body {
  font-family: Montserrat !important;
  position: relative;
  // ____________________________________
  // General styles
  // ____________________________________
  .mainContainer {
    background: #f7fafa;
  }
  .alertWrapper {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 9999;
    top: 50px;

    .alert {
      width: 80%;
    }
  }
  .fieldInput {
    height: 52px;
  }
  a {
    color: black;
    text-decoration: underline;
    cursor: pointer;
  }
  .button {
    height: 52px;
    width: 200px;
    background: #13cde1;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
  }
  input[type='search']::-webkit-clear-button {
    display: none;
  }
  .circleIcon {
    border-radius: 50%;
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #219653;
    transition: all 0.3s ease;
    transition-property: background-color;
    cursor: pointer;
    &.photosChevronIcon {
      padding: 0;
      width: 22px;
      height: 22px;
    }
    &.redIcon {
      background-color: #f13c3c;
    }
    &.greyIcon {
      background-color: #9fa3a9;
    }
    &.detailsIcon {
      background-color: #f1f5f8;
      .MuiSvgIcon-root {
        fill: #000 !important;
      }
      &:hover {
        background-color: #219653;
        .MuiSvgIcon-root {
          fill: #fff !important;
        }
      }
    }
    .MuiSvgIcon-root {
      fill: #fff !important;
      transition: all 0.3s ease;
      transition-property: fill;
    }
  }
  .disabledIcon {
    cursor: default;
    background: #9fa3a9;
    opacity: 0.65;
    .MuiSvgIcon-root {
      fill: white !important;
    }
  }
  .hoverIcon {
    border-radius: 50%;
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    &:hover {
      background: #219653;
      &.redIcon {
        background: #f13c3c;
      }
      .MuiSvgIcon-root {
        fill: white !important;
      }
    }
  }
  .sidebarImageViewer #ReactSimpleImageViewer {
    width: 75%;
    height: 100%;
  }
  .actionIcon {
    background: #f4f4f4;
    padding: 10px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #374252;
    &:hover,
    &.actionIconActive {
      background: #374152;
      color: white;
      path {
        stroke: #fff;
      }
    }
  }

  // ____________________________________
  // Header
  // ____________________________________
  .header {
    width: 100%;
    height: 80px;
    background: #374152;
    color: white;
    .innerWrapper {
      // max-width: 80%;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 40%;
        .logo {
          cursor: pointer;
        }
        .search {
          margin-left: 40px;
          color: #acb0bb;
          font-weight: 400;
          input::placeholder {
            color: #acb0bb;
            font-weight: 400;
            opacity: 1;
          }
        }
        .filterButton {
          margin-left: 20px;
          text-transform: none;
          height: 42px;
          min-width: 142px;
          background: white;
          border: none;
          color: #374152;
          font-size: 14px;
          font-weight: 600;
          min-width: 90px;
          padding: 10 30;
          text-transform: uppercase;
        }
      }
      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 40%;
        .menu {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          box-shadow: none;
          border: none;
          button {
            background: #374152;
          }
        }
        .headerMenuDropdown {
          z-index: 2;
          .MuiPaper-root {
            background: #374152;
            color: white;
          }
          .MuiList-root {
            padding: 0;
            .headerDropdownItem {
              padding: 19px 27px;
              border: 1px solid #fff;
            }
          }
        }
        .user {
          margin-left: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          img {
            margin-right: 10px;
          }
        }
        .logout {
          margin-left: 20px;
          cursor: pointer;
          svg {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }

  // ____________________________________
  // Content
  // ____________________________________
  .contentWrapper {
    max-width: 1370px;
    padding: 0 15px;
    margin: 0 auto;
    overflow: auto;
  }

  // ____________________________________
  // SIGN IN
  // ____________________________________
  .signIn {
    width: 100vw;
    height: 100vh;
    .logoWrapper {
      max-width: 80%;
      margin: 0 auto;
      .logo {
        width: 240px;
        height: 27px;
      }
    }
    .formWrapper {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .formInnerWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        .title {
          color: #1d1d1d;
          font-size: 18px;
          font-weight: 700;
          line-height: 140%;
          margin-bottom: 8px;
        }
        .fieldInput {
          width: 330px;
        }
        .rememberMe span,
        .forgotPass {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  // ____________________________________
  // MUI OVERRIDE
  // ____________________________________
  .MuiDataGrid-root {
    border: none;
    color: #374152;
    font-weight: bold;
    .MuiDataGrid-columnSeparator {
      visibility: hidden;
    }
    .MuiDataGrid-columnHeader {
      .MuiDataGrid-columnHeaderTitle {
        color: #374152;
        font-weight: 800;
        font-size: 16px;
        &:hover {
          color: black;
          .MuiButtonBase-root .MuiSvgIcon-root {
            fill: black;
          }
        }
      }
      .MuiDataGrid-iconButtonContainer {
        visibility: visible;
        .MuiButtonBase-root {
          &:hover {
            background-color: unset;
          }
          .MuiSvgIcon-root {
            fill: #374152;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
    .MuiDataGrid-row {
      color: #374152;
      font-weight: bold;
      background: white;
      &:hover {
        color: black;
        background: #f4f7f9;
      }
      .MuiDataGrid-cell {
        border: none;
      }
    }
  }

  // ____________________________________
  // TRACKING
  // ____________________________________
  .tableRow {
    .MuiDataGrid-cell:last-child {
      border-right: 4px solid;
      border-radius: 5px;
    }
    &.tableRow-DELIEVERED .MuiDataGrid-cell:last-child {
      border-color: #2897ff;
    }
    &.tableRow-NEW .MuiDataGrid-cell:last-child {
      border-color: #6db86a;
    }
    &.tableRow-SHIPPED .MuiDataGrid-cell:last-child {
      border-color: #2897ff;
    }
    &.tableRow-RELEASED .MuiDataGrid-cell:last-child {
      border-color: #6db86a;
    }
    &.tableRow-FINALIZED .MuiDataGrid-cell:last-child {
      border-color: #464646;
    }
    &.tableRow-RELEASED_WITH_DEBTS .MuiDataGrid-cell:last-child {
      border-color: #a1971d;
    }
  }
  .tableImage {
    max-width: 50px;
    width: 50px;
  }
  .statusPill {
    padding: 10px;
    border-radius: 25px;
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 14px;
    padding: 0;
    font-weight: bold;
    &.tableStatus {
      max-width: 100%;
      text-align: center;
    }
    div {
      line-height: normal;
      padding: 5px 10px;
    }
  }
  .sidebarBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    background: #0e0c14;
    z-index: 2;
    transition: all 0.3s ease;
    transition-property: opacity;
  }
  .sidebar {
    position: absolute;
    height: calc(100% - 80px);
    right: 0;
    top: 80px;
    width: 0;
    max-width: 25%;
    // min-width: 420px;
    transition: all 0.3s ease;
    transition-property: width, right;
    overflow: hidden;
    background: #f8fafa;
    overflow-y: auto;
    z-index: 10;
    .sidebarWrapper {
      width: 80%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      padding-top: 30px;
      .sidebarTopWrapper {
        width: 100%;
        .sidebarTop {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .sidebarTopActions {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 10px 0 0;
            .action {
              &:not(:first-child) {
                margin-left: 10px;
              }
            }
          }
          .closeSidebar {
            cursor: pointer;
            fill: #374152;
            width: 44px;
            height: 44px;
            opacity: 0.3;
            transition: all 0.3s ease;
            transition-property: opacity;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      .sidebarCarInfo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 20px;
        .carInfoPhoto {
          width: 80px;
          .sidebarCarImage {
            width: 67px;
          }
        }
        .carInfoRight {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-left: 20px;
          .carInfoTop {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            color: #464646;
            font-size: 14px;
            .carInfoTitle {
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              text-transform: uppercase;
            }
            .actionShare {
              padding: 5px;
              margin-left: 10px;
              svg {
                width: 16px;
                height: 16px;
              }
            }
          }
          .carInfoBottom {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            font-weight: bold;
            color: #464646;
            font-size: 14px;
            .carInfoStatus {
              margin-left: 20px;
              div {
                padding: 2px 10px;
              }
            }
          }
        }
      }
      .sidebarFields {
        width: 100%;
        .sidebarFieldsSection {
          margin-top: 40px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          .title {
            color: #464646;
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
          }
          .subTitle {
            color: #464646;
            font-size: 14px;
            font-weight: 500;
            line-height: 140%;
            margin-top: 10px;
          }
          .fieldsWrapper {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
          }
        }
        .field {
          width: 100%;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
          .fieldWrapper {
            background: white;
            padding: 16px 19px 14px 20px;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            &.photosField {
              cursor: pointer;
            }
            &.statusField {
              cursor: pointer;
              justify-content: flex-start;
            }
            .leftSide {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              &.filesFiledWrapper {
                justify-content: space-between;
                width: 100%;
                .label {
                  width: 70%;
                }
              }
              .leftFieldIcon {
                margin-right: 10px;
              }
              .label {
                color: #2d3038;
                font-size: 14px;
                font-weight: 500;
                &.fileLabel {
                  margin-left: 20px;
                }
                &.labelBold {
                  color: #464646;
                  font-size: 16px;
                  font-weight: 600;
                }
              }
            }
            .rightSide {
              .value {
                color: #151e11;
                font-size: 14px;
                font-weight: 600;
              }
              .downloadIcon {
                fill: #219653;
                cursor: pointer;
              }
            }
            .statusCheckbox {
              width: 25px;
              height: 25px;
              border-radius: 50%;
              border: 1px solid #b0b0b0;
              background: white;
              margin-right: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              &.selected {
                background: #219653;
                border-color: #219653;
              }
              img {
                width: 12px;
                height: 12px;
              }
            }
          }
          .photosContainer {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 10px 0;
            img {
              margin-bottom: 10px;
              max-width: 100%;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .newFileWrapper {
            padding: 4px 20px;
            .fieldInput {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }

  // ____________________________________
  // DETAILS
  // ____________________________________
  .detailsWrapper {
    .title {
      color: #1d1d1d;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.01em;
      line-height: 140%;
      margin-bottom: 55px;
      text-transform: uppercase;
      margin-top: 100px;
    }
    .columns {
      display: flex;
      justify-content: space-between;
      position: relative;
      flex-wrap: wrap;
      .column {
        width: 32%;
        .subTitle {
          color: #1d1d1d;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          letter-spacing: 0.01em;
          line-height: 140%;
          margin-bottom: 25px;
          text-transform: uppercase;
        }
        .detailsPhotosWrapper {
          display: flex;
          flex-direction: column;
          .photosRow {
            height: 52px;
            padding: 16px 19px 14px 20px;
            width: 100%;
            align-items: center;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 0 3px #34343414;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            padding: 10px 20px;
            text-transform: uppercase;
            box-sizing: border-box;
            transition: all 0.5s ease;
            transition-property: background-color;
            margin-bottom: 10px;
            cursor: pointer;
            &.openedRow {
              background-color: #ccc !important;
              .photosChevronIcon {
                background-color: #219653;
                .MuiSvgIcon-root {
                  fill: #fff !important;
                }
              }
            }
            &:hover {
              background-color: #ccc;
            }
            .left {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .photosRowTitle {
                margin-left: 18px;
                font-size: 14px;
                font-weight: 500;
                padding: 0;
                text-align: left;
                width: 100%;
              }
            }
          }
          .sliderWrapper {
            margin-bottom: 10px;
          }
        }
        .detailsFields {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          .detailsField {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 10px;
            padding: 14px 20px 14px 17px;
            box-sizing: border-box;
            :last-child {
              margin-bottom: 0;
            }
            .fieldValue {
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  // ____________________________________
  // MEDIA QUERIES
  // ____________________________________
  @media (min-width: 675px) {
    .contentWrapper {
      max-width: 675px;
    }
    .header .innerWrapper {
      max-width: 675px;
    }
  }
  @media (min-width: 768px) {
    .contentWrapper {
      max-width: 768px;
    }
    .header .innerWrapper {
      max-width: 768px;
    }
  }
  @media (min-width: 992px) {
    .contentWrapper {
      max-width: 992px;
    }
    .header .innerWrapper {
      max-width: 992px;
    }
  }
  @media (min-width: 1200px) {
    .contentWrapper {
      max-width: 1200px;
    }
    .header .innerWrapper {
      max-width: 1200px;
    }
  }
  @media (min-width: 1400px) {
    .contentWrapper {
      max-width: 1400px;
    }
    .header .innerWrapper {
      max-width: 1400px;
    }
  }
  @media screen and (max-width: 970px) {
    .detailsWrapper .columns .column {
      width: 49%;
    }
  }
  @media screen and (max-width: 780px) {
    .detailsWrapper .columns .column {
      width: 100%;
      margin-bottom: 25px;
    }
  }

  .sliderArrow {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 2;
    &.prevArrow {
      left: 0;
      right: unset;
    }
    svg {
      cursor: pointer;
    }
  }
  .carousel-status {
    display: none;
  }
  .control-dots {
    display: none;
  }
  .carousel {
    width: 438px;
    margin: 0;
    .thumbs-wrapper {
      margin: 0;
      .thumbs {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        overflow: auto;
        transform: none !important;
        .thumb {
          margin: 0;
          padding: 0;
          min-width: 80px;
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
